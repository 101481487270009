<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:45
-->
<template>
	<div class="hearApplyDetail">
		<common-title :title="'详情'" />
		<div class="hearApplyDetail-content">
			<div class="hearApplyDetail-table">
				<el-table :data="tableData" border>
					<el-table-column width="65px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="name" label="姓名">
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="collegeName" label="学院班级">
						<template slot-scope="scope">
							<span>{{scope.row.collegeName}}{{scope.row.faculty}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="workingHours" label="工时数">
						<template slot-scope="scope">
							<span>{{scope.row.workingHours.workingHours}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="activityId" label="活动ID">
						<template slot-scope="scope">
							<span>{{scope.row.activityId}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="activityName" label="活动名称">
						<template slot-scope="scope">
							<span>{{scope.row.activity.activityName}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="status" label="活动日期">
						<template slot-scope="scope">
							<div class="flex-column">
								<span>{{`${dateFormat(scope.row.activity.starTime)} 至 ${dateFormat(scope.row.activity.endTime)}`}}</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="hearApplyDetail-btn">
				<el-button @click="submitMini" type="primary">开具证明并发到送到小程序</el-button>
				<el-button @click="refuseMini">拒绝</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import { detailHandleApply, agreeHandleApply, refuseHandleApply } from "@/api/electronic"
	export default {
		data() {
			return {
				tableData: [],
				detailId: ""
			};
		},
		created() {
			this.detailId = this.$route.query.detailId
			this.getHandleApplyDetail()
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				} else {
					return ""
				}
			},
			getHandleApplyDetail() {
				detailHandleApply(this.detailId, {id: this.detailId}).then((res) => {
					if(res.code == 0) {
						this.tableData = res.data
					}
				})
			},
			submitMini() {
				agreeHandleApply(this.detailId, {id: this.detailId}).then((res) => {
					if(res.code == 0) {
						this.$message.success("开具证明并发到送到小程序成功~")
						this.$router.go(-1)
					}
				})
			},
			refuseMini() {
				refuseHandleApply(this.detailId, {id: this.detailId}).then((res) => {
					if(res.code == 0) {
						this.$message.success("审理申请已拒绝~")
						this.$router.go(-1)
					}
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.hearApplyDetail-btn .el-button--default {
		border: 1px solid #DCDFE6 !important;
		color: #606266 !important;
		height: 40px;
	}
	.flex-column {
		display: flex;
		flex-direction: column;
	}
	.hearApplyDetail {
		.hearApplyDetail-content {
			padding: 20px 20px;
			
			.hearApplyDetail-btn {
				display: flex;
				margin-top: 20px;
			}
		}
	}
</style>
